<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">
    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Tomemos tus medidas
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        Algunos de nuestros perfiles de trabajo requieren el uso de uniformes y/o equipos de protección,
        por lo que nos sería muy útil conocer algunos datos sobre tus características físicas.
      </div>
    </div>

    <ValidationObserver ref="formstepPiI" tag="div"  class="ppi_contentZone spacerRowTop20" v-slot="{ handleSubmit, valid, passed }">
      <q-form 
        ref="myFormPiI"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPiiReset"
        class="formDetailZone "
      >
        <template v-if="(222===333)">
          <div class="dataSection_container spacerRowTop48">
            <div class="dataSection_title">Sexo</div>
            <div class="datasectionItem dataSection_separadorx">
              <div class="row">
                  <ValidationProvider ref="fldx_sexo" name="sexo" :immediate="false" class="col-12 canna_forma_campo_container" tag="div" style="margin-left: -10px;"
                    :rules=" { required: true, oneOf: z_allowSexo } "
                    v-slot="{ errors, invalid, validated  }"
                  >
                      <input type="hidden" name="sexo"  v-model="frmData.sexo">
                      <q-option-group
                        class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
                        inline
                        name="tieneTCOpt"
                        type="radio"
                        keep-color
                        :options="[
                          { label: 'Masculino', value: 2, color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                          { label: 'Femenino', value: 1, color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }
                        ]"
                        :color="(invalid && validated) ? 'positive' : 'canna_ColorRadio'"
                        v-model="frmData.sexo"
                      />
                      <template v-if="(invalid && validated && errors[0] !==undefined)">
                        <div class="q-field--error q-field__bottom" 
                          style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
                        >
                        {{ errors[0] }}
                        </div>
                      </template>
                  </ValidationProvider>
              </div>
            </div>
          </div>
        </template>

        <div class="dataSection_container spacerRowTop26">
          <div class="dataSection_title">Características físicas</div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row row q-gutter-md " :style="(is_Mobile|| $q.screen.lt.sm) ? '' : 'max-width: 500px;'" >
              <!-- campo estatura -->
              <div class="col">
                <!-- Campo de estatura (select) -->
                <ValidationProvider
                  :rules=" { required: true, oneOf: z_allowEstatura } " :immediate="false" ref="fldx_estatura" name="estatura" 
                  v-slot="{ errors, invalid, validated  }"
                >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect"
                        :class="{'fieldError':invalid && validated }"
                      >Estatura</div>
                      <div class="requiredLabel text-right" >metros</div>
                    </div>
                    <q-select
                      name="estatura"
                      filled
                      :dense="true"
                      v-model="frmData.estatura"
                      :label="(frmData.estatura ==='' || frmData.estatura === null) ? 'Elige una opción' : ''"
                      :options="cat_Estatura.dataCatalog"
                      option-value="id"
                      option-label="nombre"
                      emit-value
                      map-options
                      :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ? errors[0].replace('estatura','Estatura').replace('El campo','') : '' "
                    >
                      <template v-slot:append>
                        <q-icon
                          v-if="frmData.jornada !== null"
                          class="cursor-pointer" 
                          name="clear"
                          @click.stop="frmData.estatura = null"
                        />
                      </template>

                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label>
                              {{scope.opt.nombre}}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>

                    </q-select>
                </ValidationProvider>
              </div>
              <!-- campo Peso -->
              <div class="col">
                <!-- Campo de peso (select) -->
                <ValidationProvider
                  :rules=" { required: true, oneOf: z_allowPeso } " :immediate="false" ref="fldx_peso" name="peso" 
                  v-slot="{ errors, invalid, validated  }"
                >
                    <div style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel labelFx_Field text-left noselect"
                        :class="{'fieldError':invalid && validated }"
                      >Peso</div>
                      <div class="requiredLabel text-right" >kilogramos</div>
                    </div>
                    <q-select
                      name="peso"
                      filled
                      :dense="true"
                      v-model="frmData.peso"
                      :label="(frmData.peso ==='' || frmData.peso === null) ? 'Elige una opción' : ''"
                      :options="cat_Peso.dataCatalog"
                      option-value="id"
                      option-label="nombre"
                      emit-value
                      map-options
                      :behavior="( pageSize === 'xs' || is_Mobile==true ) ? 'dialog' : 'menu' "
                      :error="invalid && validated"
                      :error-message="(errors[0] !==undefined) ? errors[0].replace('peso','Peso').replace('El campo','') : '' "
                    >
                      <template v-slot:append>
                        <q-icon
                          v-if="frmData.peso !== null"
                          class="cursor-pointer" 
                          name="clear"
                          @click.stop="frmData.peso = null"
                        />
                      </template>
                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            <q-item-label>
                              {{scope.opt.nombre}}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                </ValidationProvider>
              </div>

            </div>
          </div>
        </div>


        <div class="dataSection_container spacerRowTop26">

            <ValidationProvider 
              ref="fldx_tallas" name="talla" :immediate="true" 
              class="col-12 canna_forma_campo_container" tag="div" style="margin-left: 0px;"
              :rules=" { required: true, oneOf: zzz_allowCurrentTallasUse } "
              v-slot="{ errors, invalid, validated  }"
            >
              <div class="dataSection_title" :class="{'fieldError':invalid && validated }">
                Talla
              </div>
              <div class="datasectionItem dataSection_separadorx canna_forma_campo_container">

                <input type="hidden" name="talla"  v-model="frmData.talla">

                <!-- Fila de tallas Hombre -->
                <div class="row" :style="(is_Mobile|| $q.screen.lt.sm) ? '' : 'max-width: 400px;'" v-show="(frmData.sexo===2)" >
                  <div class="col-12 row q-gutter-xs">
                    <template v-for="(talla, index) in frmData.tallas_list_Hombre">
                      <div class="col" :key="'btnx_tm_' + index">
                        <q-btn
                          :outline="talla.selected ? false : true"
                          :color="'app_mainBtn'"
                          :label="talla.nombre"
                          @click.stop="pii_doSetTallaItem(2, talla, $event)"
                          style="width:98%;"
                        />
                      </div>
                    </template>
                  </div>
                </div>

                <!-- Fila de tallas Mujer -->
                <div class="row" style="margin-top:4px" :style="(is_Mobile|| $q.screen.lt.sm) ? '' : 'max-width: 400px;'" v-show="(frmData.sexo===1)">
                  <div class="col-12 row q-gutter-xs">
                    <template v-for="(talla, index) in frmData.tallas_list_Mujer">
                      <div class="col" :key="'btnx_tm_' + index">
                        <q-btn
                          :outline="talla.selected ? false : true"
                          :color="'app_mainBtn'"
                          :label="talla.nombre"
                          @click.stop="pii_doSetTallaItem(1, talla, $event)"
                          style="width:98%;"
                        />
                      </div>
                    </template>
                  </div>
                </div>

                <!-- Fila de tallas universales -->
                <div class="row" style="margin-top:4px" :style="(is_Mobile|| $q.screen.lt.sm) ? '' : 'max-width: 400px;'" >
                  <div class="col-12 row q-gutter-xs">
                    <template v-for="(talla, index) in frmData.tallas_list_universal">
                      <div class="col" :key="'btnx_tm_' + index">
                        <q-btn
                          :outline="talla.selected ? false : true"
                          :color="'app_mainBtn'"
                          :label="talla.nombre"
                          style="width:98%; cursor:not-allowed;"
                        />
                      </div>
                    </template>
                  </div>
                </div>

                <template v-if="(invalid && validated && errors[0] !==undefined)">
                  <div class="q-field--error q-field__bottom" 
                    style="flex: 1 1 0%; justify-content: flex-start; padding: 10px 0px 0px 0px; display: flex; align-items: center;"
                  >
                  {{ errors[0].replace('El campo','').replace('talla','La talla').replace('obligatorio','obligatoria') }}
                  </div>
                </template>

              </div>

            </ValidationProvider>

        
        </div>

        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>
  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../../boot/axios' // APP code 
import logOut_Mixin from '../../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import { helperCatsTallas_Mixin } from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

const tplpii_Data = {
  sexo: null,
  estatura: null,
  peso: null,
  talla: null,
  tallas_list_Hombre: [], // CAT 19 Listado original para captura de datos (Listados fijos)
  tallas_list_Mujer: [] , // CAT 20 Listado original para captura de datos (Listados fijos)
  tallas_list_universal:[] // CAT 63 Listado original para captura de datos (Listados fijos)
}

export default {
  name: 'psi_screen_medidas',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    helperCatsTallas_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () {
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      zinitData: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      zRef_tallasU: [],
      z_allowSexo: [1,2],
      z_allowEstatura: [],
      z_allowPeso: [],
      z_allowTallasF:[],
      z_allowTallasM:[],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: JSON.parse(JSON.stringify(tplpii_Data)),
      frmData: JSON.parse(JSON.stringify(tplpii_Data)), 
    }
  },
  computed: {
    //1,63,53,52,19,20
    profileSexo () {
      if (this.$store.state.myapp.appUserProfile.hasOwnProperty('sexo')){ 
        return this.$store.state.myapp.appUserProfile.sexo
      } else { 
        return ''
      }
    },
    zzz_allowCurrentTallasUse () { 
      if (this.frmData.sexo === 1) { 
        return this.z_allowTallasF
      } else if (this.frmData.sexo === 2) { 
        return this.z_allowTallasM
      } else { 
        return []
      }
    },
    cat_Sexo () { // catalogo id = 1 Genero  (F:1   M:2)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(1) : this.getHelperCat(1)
    },
    cat_Peso () { // catalogo id = 53 Peso
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(53) : this.getHelperCat(53)
    },
    cat_Estatura () { // catalogo id = 52 Estatura 
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(52) : this.getHelperCat(52)
    },
    cat_tallaHombre () { // catalogo id = 19 Tallas de hombre
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(19) : this.getHelperCat(19)
    },
    cat_tallaMujer () { // catalogo id = 20 Tallas de mujer
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(20) : this.getHelperCat(20)
    },
    cat_Universales () { // catalogo id = 63 Tallas / Medidas Universales 758 a 763
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(63) : this.getHelperCat(63)
    },
    mainErrors () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formstepPiI) {
            if (this.$refs.formstepPiI.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiI.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiI.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {
    'frmData.sexo': function (newVal, oldVal){ 
      if (this.zinitData === true){
        if (newVal!==oldVal && newVal!== null && newVal!==undefined){ // Ajustamos q se vea la talla correspondiente 
          this.pii_changeSexoVal( newVal, oldVal )
        }
      }
    }
  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_medidas --   test')
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/direccion-d'
      this.$cannaDebug('-- psiPage_medidas --   do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    onStepFormPiiReset () {

    },
    pii_changeSexoVal: function(newVal, oldVal){
      this.$cannaDebug('-- psiPage_medidas --   pii_changeSexoVal --- ')
      if (this.zinitData === false ){ 
        return
      }

      if (newVal!==oldVal){
        if (newVal == 1){ // Femenino 
          this.frmData.tallas_list_Mujer.forEach(item => { 
            item.selected = false
          })
        } else if (newVal == 2){ // Masculino 
          this.frmData.tallas_list_Hombre.forEach(item => { 
            item.selected = false
          })
        }
        this.frmData.tallas_list_universal.forEach(item => { 
            item.selected = false
        })
        this.frmData.talla = null
        this.$forceUpdate()

        setTimeout(() => {
          if (this.$refs.fldx_tallas){ 
            this.$refs.fldx_tallas.validate()
          }
        }, 200)

      }

    },
    pii_doSetTallaItem( sexo, tallaItem, event, notErase ) {
      if (this.frmData.talla !== tallaItem.id) {
        this.frmData.talla = tallaItem.id
      } else if (this.frmData.talla === tallaItem.id) {
        if (notErase === undefined || notErase===false || notErase===null){
          this.frmData.talla = null
        }
      }
      this.pii_doSetTallaSelecteds ( sexo, this.frmData.talla )
      this.pii_doSetUniversalTalla ( sexo, this.frmData.talla )
    },
    pii_doSetTallaSelecteds ( sexo, value){
      if (sexo===1){  // Femenino
        this.frmData.tallas_list_Mujer.forEach(item => { 
          item.selected = (item.id===value) ? true : false
        })
      } else if (sexo == 2){ // Masculino 
        this.frmData.tallas_list_Hombre.forEach(item => { 
          item.selected = (item.id===value) ? true : false
        })
      }
    },
    pii_doSetUniversalTalla (sexo, value ){
      if (value === null){
        this.frmData.tallas_list_universal.forEach(item => { 
            item.selected = false
        })
      } else {
        var indexUniversal = null
        if (sexo===1){  // Femenino
          indexUniversal = this.list_refTallas.findIndex( item => item.f_val === value )
        } else if (sexo == 2){ // Masculino 
          indexUniversal = this.list_refTallas.findIndex( item => item.m_val === value )
        }
        if (indexUniversal!==null) {
          var valorUse = this.list_refTallas[indexUniversal].u_val
          this.frmData.tallas_list_universal.forEach(item => { 
              item.selected = (item.id===valorUse) ? true : false
          })
        }
      }
      this.$forceUpdate()
    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_medidas --   do Submit Data --- ')
      var test = 11 
      var toAdd = [], toRemove = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1)

      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 
      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 ) { // Que existan valores...!

        // SEXO 
        if (testA._.sexo.status === 'MODIFIED') {
          var item = testA._.sexo 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 && (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') {
            toRemove.push(item.original)
          }
        } else if (testA._.sexo.status === 'EQUAL' &&  this.profileRecords.length<=0) {
          var item = testA._.sexo 
          toAdd.push(item.current)
        }
        // Estatura 
        if (testA._.estatura.status === 'MODIFIED') {
          var item = testA._.estatura 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') {
            toRemove.push(item.original)
          }
        }
        // peso 
        if (testA._.peso.status === 'MODIFIED') {
          var item = testA._.peso 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') {
            toRemove.push(item.original)
          }
        }
        // talla 
        if (testA._.talla.status === 'MODIFIED') {
          var item = testA._.talla 
          if (item.status === 'ADDED') {
            toAdd.push(item.current)
          } else if (item.status === 'MODIFIED') {
            if (this.profileRecords.length > 0 &&  (item.original!=='' && item.original!==null)){ 
              toRemove.push(item.original)
            }
            toAdd.push(item.current)
          } else if (item.status === 'DELETED') {
            toRemove.push(item.original)
          }
        }
        // -----------------
        test = 333
        // if (test !==  22 ){
        //   alert('Escape de prueba')
        //   this.showLoadx(false, 1)
        //   return false
        // }
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')
        // ----------------------
        // Datos a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'picDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1)
            return false
          }
        }
        // ----------------------
        // Datos para agregar
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1)
            return false
          }
        }
        // Terminamos
        // Actualizar Datos de Estatus // WIP 

        // -----------
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData))
        allowNextPage = true
      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.MEDIDAS){ // < 21
        await this.hpb_updateProfileStatus( this.zstatUser.MEDIDAS ).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/aptitudes-a'
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      var createdJSON =  JSON.parse(JSON.stringify(this.frmData))

      // Hasta aqui estamos bien y obtenemos los datos de profile
      // [1,63,53,52,19,20] 
      // NOTA CATEG 63 (universales no se usa en datos)
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { categoria_id: '1,53,52,19,20', 'per-page': 'all', expand:'catalogo'}, true, null)

      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpii_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpii_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))
      //-----------------------------
      var SexoBase = this.profileSexo

      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores
        var valSexo = null 
        var valPeso = null 
        var valEstatura = null 
        var valTalla = null 

        profileData.forEach(itemP => {
          if (itemP.categoriaId === 1) { // Sexo
            valSexo = itemP.catalogo_id
          }
        })

        profileData.forEach(itemP => {
          if (itemP.categoriaId === 53) { // peso 
            valPeso = itemP.catalogo_id
          } else  if (itemP.categoriaId === 52) { // estatura
            valEstatura = itemP.catalogo_id
          } else { // x sexo 
            if (valSexo === 2) {
              if (itemP.categoriaId === 19) { // Tallas de hombre
                valTalla = itemP.catalogo_id
              }
            } else if (valSexo === 1){ // Femenino 
              if (itemP.categoriaId === 20) { // Tallas de Mujer
                valTalla = itemP.catalogo_id
              }
            }
          }
        })

        var newData = {
          sexo: valSexo,
          estatura: valEstatura,
          peso: valPeso,
          talla: valTalla,
          tallas_list_Hombre: JSON.parse(JSON.stringify(createdJSON.tallas_list_Hombre)), 
          tallas_list_Mujer: JSON.parse(JSON.stringify(createdJSON.tallas_list_Mujer)),
          tallas_list_universal: JSON.parse(JSON.stringify(createdJSON.tallas_list_universal))
        }
        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

        // Establecemos el boton de talla 

        var indexUse = null 
        var tallaItemx = null 
        if (valTalla !== null ){
          if (valSexo === 1){
            indexUse = this.frmData.tallas_list_Mujer.findIndex(itemx=> itemx.id=== valTalla)
            this.pii_doSetTallaItem( valSexo, this.frmData.tallas_list_Mujer[indexUse], null, true )
          } else if (valSexo === 2){ 
            indexUse = this.frmData.tallas_list_Hombre.findIndex(itemx=> itemx.id=== valTalla)
            this.pii_doSetTallaItem( valSexo, this.frmData.tallas_list_Hombre[indexUse], null, true )
          }
        }
        
        this.$forceUpdate()
        await this._waitRequestProc(200)

      } else { // No hay info todavia  //se usa el sexo por default 

        var baseDataUse = JSON.parse(JSON.stringify(tplpii_Data))
        baseDataUse.tallas_list_Hombre = JSON.parse(JSON.stringify(createdJSON.tallas_list_Hombre))
        baseDataUse.tallas_list_Mujer = JSON.parse(JSON.stringify(createdJSON.tallas_list_Mujer))
        baseDataUse.tallas_list_universal = JSON.parse(JSON.stringify(createdJSON.tallas_list_universal))
        baseDataUse.sexo = (SexoBase==='M') ? 2 : 1

        this.frmDataOriginal = JSON.parse(JSON.stringify(baseDataUse))
        this.frmData = JSON.parse(JSON.stringify(baseDataUse))
      }
      this.zinitData = true
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_medidas --   beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_medidas --   created')
    this.zloadingData = true 
    this.zinitData = false 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_medidas --    beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_medidas --   mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)
    var categorias = [1,63,53,52,19,20] 

    // if (this.$refs.fldx_sexo){ this.$refs.fldx_sexo.reset() }
    if (this.$refs.fldx_estatura){ this.$refs.fldx_estatura.reset() }
    if (this.$refs.fldx_peso){ this.$refs.fldx_peso.reset() }
    if (this.$refs.fldx_tallas){ this.$refs.fldx_tallas.reset() }

    for (let categx of categorias) { 
      if (this.getHelperCat(categx).dataLoaded===false){ 
        var testCatA = false 
        await this.helpermix_getCatalog (categx, null).then( respx => { 
          this.$cannaDebug('-- psiPage_horario -- categoria ' + categx  + ' finish ')
          testCatA = respx 
        })
      }
      await this._waitRequestProc(100)
    }
    await this._waitRequestProc(200)
    //----------- Sexo 
    var allowedVals = []
    this.getHelperCat(1).dataCatalog.forEach( item => { 
      allowedVals.push(item.id)
    })
    this.z_allowSexo = allowedVals.slice(0)
    allowedVals = []
    //-----------  Estatura
    this.getHelperCat(52).dataCatalog.forEach( item => { 
      allowedVals.push(item.id)
    })
    this.z_allowEstatura = allowedVals.slice(0)
    allowedVals = []
    //----------- Peso 
    this.getHelperCat(53).dataCatalog.forEach( item => { 
      allowedVals.push(item.id)
    })
    this.z_allowPeso = allowedVals.slice(0)
    allowedVals = []
    //-----------
    // Tallas 
    var listData = []
    var listDataIds = []
    this.getHelperCat(19).dataCatalog.forEach(item => {
      var newRec = {
        id: item.id, nombre: item.nombre, categoria_id: item.categoria_id, selected: false
      }
      listData.push (newRec)
      listDataIds.push(item.id)
    })
    this.frmData.tallas_list_Hombre = listData.slice(0) // CAT 19 Listado original para captura de datos (Listados fijos)
    this.z_allowTallasM = listDataIds.slice(0)
    listData = []
    listDataIds = []
    //-------------
    this.getHelperCat(20).dataCatalog.forEach(item => {
      var newRec = {
        id: item.id, nombre: item.nombre, categoria_id: item.categoria_id, selected: false
      }
      listData.push (newRec)
      listDataIds.push(item.id)
    })
    this.frmData.tallas_list_Mujer = listData.slice(0) // CAT 20 Listado original para captura de datos (Listados fijos)
    this.z_allowTallasF = listDataIds.slice(0)
    listData = []
    listDataIds = []
    //-------------
    this.getHelperCat(63).dataCatalog.forEach(item => {
      var newRec = {
        id: item.id, nombre: item.nombre, categoria_id: item.categoria_id, selected: false
      }
      listData.push (newRec)
    })
    this.frmData.tallas_list_universal = listData.slice(0) // CAT 63 Listado original para captura de datos (Listados fijos)    
    //-------------
    await this._waitRequestProc(100)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()

    // if (this.$refs.fldx_sexo){ this.$refs.fldx_sexo.reset() }
    if (this.$refs.fldx_estatura){ this.$refs.fldx_estatura.reset() }
    if (this.$refs.fldx_peso){ this.$refs.fldx_peso.reset() }
    if (this.$refs.fldx_tallas){ this.$refs.fldx_tallas.reset() }

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_medidas --   beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_medidas --   updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_medidas --   activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)

      await this._waitRequestProc(200)
      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      this.$forceUpdate()
      await this._waitRequestProc(100)

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_medidas --   deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_medidas --   beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_medidas --   destroyed')
  }
}
</script>
